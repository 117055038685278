<template> 
  <v-alert
    prominent
    type="error"
    class="ma-0 pa-5"
  >
    <v-row align="center">
      <v-col class="grow">
        {{this.text}}
      </v-col>
      <v-col class="shrink">
      <v-btn v-if="toTop" link to="/" color="grey"  @click="close" >トップへ</v-btn>
      <v-btn v-else-if="isReload" @click="reload" >リロード</v-btn>
      <v-btn v-else  @click="close" >閉じる</v-btn>
    </v-col>
    </v-row>
  </v-alert>     
</template> 

<script>
export default {
  name: 'DialogError',
  props:["text","isReload","toTop"],
  data () {
    return {
    }
  },
  methods: {
    close (){
      this.$emit('closeErrorEmit')
    },
    reload (){
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    }
  },
}
</script>
